import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"

const CustomizePriorities = () => {
  return (
    <LayoutComponent>
      <h2>Customize Priorities</h2>
      <ol>
        <li>
          Go to <strong>Priorities</strong>.
        </li>
        <li>
          Enter a priority name. You can also enter a description. This is
          optional.
        </li>
        <li>
          Select a color to represent the priority (optional but recommended).
        </li>
        <li>
          Click <strong>Add</strong>. Your priority appears at the bottom of the
          list.
        </li>
      </ol>
      <div className="testimonials">
        <h5>Note</h5>
        <p>
          GAINSS provides few pre-added priorites that cannot be deleted. You
          can add your own.
        </p>
      </div>
      <h6>Default Priority</h6>
      <p>
        GAINSS will set the default priority to <strong>Medium</strong>{" "}
        automatically while creating a new test case. You can change it.
      </p>
    </LayoutComponent>
  )
}

export default CustomizePriorities
